import React from "react";
import { ReactComponent as ColorsSvg } from "../assets/icons/new/colors.svg";
import { ReactComponent as TrashSvg } from "../assets/icons/new/trash.svg";
import { ReactComponent as ZoomOutSvg } from "../assets/icons/new/zoom-out.svg";
import { ReactComponent as ZoomInSvg } from "../assets/icons/new/zoom-in.svg";
import { ReactComponent as ChevronLeftCircleSvg } from "../assets/icons/new/chevron-left-circle.svg";
import { ReactComponent as ChevronRightCircleSvg } from "../assets/icons/new/chevron-right-circle.svg";
import { ReactComponent as FireSvg } from "../assets/icons/new/fire.svg";
import { ReactComponent as MermaidSvg } from "../assets/icons/new/mermaid.svg";
import { ReactComponent as StemSvg } from "../assets/icons/new/stem.svg";
import { ReactComponent as PlaySvg } from "../assets/icons/new/play.svg";
import { ReactComponent as CopySvg } from "../assets/icons/new/copy.svg";
import { ReactComponent as LogoCollabSvg } from "../assets/icons/new/logo-collab.svg";
import { ReactComponent as DashSvg } from "../assets/icons/new/dash.svg";
import { ReactComponent as PlusSvg } from "../assets/icons/new/plus.svg";
import { ReactComponent as NoneColorPlusSvg } from "../assets/icons/new/none-color-plus.svg";
import { ReactComponent as MaximiseSvg } from "../assets/icons/new/maximise.svg";
import { ReactComponent as FolderSvg } from "../assets/icons/new/folder.svg";
import { ReactComponent as PageSvg } from "../assets/icons/new/page.svg";
import { ReactComponent as CheckSvg } from "../assets/icons/new/check.svg";
import { ReactComponent as CollabrationSvg } from "../assets/icons/new/collabration.svg";
import { ReactComponent as DialSvg } from "../assets/icons/new/dial.svg";
import { ReactComponent as HostSvg } from "../assets/icons/new/host.svg";

export const IconColors = <ColorsSvg width={16} height={16}/>;
export const IconTrash = <TrashSvg width={16} height={16}/>;
export const IconZoomOut = <ZoomOutSvg width={16} height={16}/>;
export const IconZoomIn = <ZoomInSvg width={16} height={16}/>;
export const IconChevronLeftCircle = <ChevronLeftCircleSvg />;
export const IconChevronRightCircle = <ChevronRightCircleSvg />;
export const IconFire = <FireSvg />;
export const IconMermaid = <MermaidSvg />;
export const IconStem = <StemSvg />;
export const IconPlay = <PlaySvg />;
export const IconCopy = <CopySvg />;
export const IconLogoCollab = <LogoCollabSvg />;
export const IconDash = <DashSvg />;
export const IconPlus = <PlusSvg />;
export const IconMaximise = <MaximiseSvg />;
export const IconFolder = <FolderSvg />;
export const IconPage = <PageSvg />;
export const IconCheck = <CheckSvg />;
export const IconCollabration = <CollabrationSvg />;
export const IconNoneColorPlus = <NoneColorPlusSvg />;
export const IconDial = <DialSvg />;
export const IconHost = <HostSvg />;