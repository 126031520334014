import throttle from "lodash.throttle";
import { PureComponent } from "react";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  ImagoImperativeAPI,
  Page,
} from "../../types";
import { ErrorDialog } from "../../components/ErrorDialog";
import { API_URL, APP_NAME, COOKIES, ENV, EVENT } from "../../constants";
import { ImportedDataState } from "../../data/types";
import {
  FileId,
  ImagoElement,
  InitializedImagoImageElement,
} from "../../element/types";
import {
  getNonDeletedElements,
  getSceneVersion,
  restoreElements,
} from "../../packages/imago/index";
import { Collaborator, Gesture } from "../../types";
import {
  deleteCookie,
  preventUnload,
  resolvablePromise,
  withBatchedUpdates,
} from "../../utils";
import {
  CURSOR_SYNC_TIMEOUT,
  FILE_UPLOAD_MAX_BYTES,
  FIREBASE_STORAGE_PREFIXES,
  INITIAL_SCENE_UPDATE_TIMEOUT,
  LOAD_IMAGES_TIMEOUT,
  WS_SCENE_EVENT_TYPES,
  SYNC_FULL_SCENE_INTERVAL_MS,
  FILE_ENCRYPTION_KEY,
} from "../app_constants";
import {
  generateCollaborationLinkData,
  getCollaborationLink,
  getCollabServer,
  getSyncableElements,
  SocketUpdateDataSource,
  SyncableImagoElement,
} from "../data";
// import {
//   isSavedToFirebase,
//   loadFilesFromFirebase,
//   loadFromFirebase,
//   saveFilesToFirebase,
//   saveToFirebase,
// } from "../data/firebase";

import {
  isSavedToCollabData,
  loadFilesFromCollabData,
  loadFromCollabData,
  saveFilesToCollabData,
  // saveToCollabData,
  deleteFilesFromCollabData,
  saveToCollabDataNew,
  saveUserSceneData,
  loadUserSceneFromStoreData,
} from "../data/CollabData";

import {
  getCurrBoardMode,
  getCurrPageFromStorage,
  getPageDataByKeyFromLocalStorage,
  getPageListFromStorage,
  importUsernameFromLocalStorage,
  reSetCollabtionHost,
  saveUsernameToLocalStorage,
  setCurrPageToStorage,
  setPageListToStorage,
} from "../data/localStorage";
import Portal from "./Portal";
import RoomDialog from "./RoomDialog";
import { t } from "../../i18n";
import { UserIdleState } from "../../types";
import { IDLE_THRESHOLD, ACTIVE_THRESHOLD } from "../../constants";
import {
  encodeFilesForUpload,
  FileManager,
  updateStaleImageStatuses,
} from "../data/FileManager";
import { AbortError } from "../../errors";
import {
  isImageElement,
  isInitializedImageElement,
} from "../../element/typeChecks";
import { newElementWith } from "../../element/mutateElement";
import {
  ReconciledElements,
  reconcileElements as _reconcileElements,
} from "./reconciliation";
import { decryptData } from "../../data/encryption";
import { resetBrowserStateVersions } from "../data/tabSync";
import { LocalData } from "../data/LocalData";
import { atom, useAtom, useSetAtom } from "jotai";
import { jotaiStore } from "../../jotai";
import {
  clearElementsForDatabase,
  clearElementsForExport,
} from "../../element";
import { serializeAsJSONPage } from "../../data/json";
import { getPageData } from "../../data";

import {
  isFlashCollabingAtom,
  isGoogleMeetAddonAtom,
} from "../../components/App";
import { isNullishCoalesce } from "typescript";
import { PageManager } from "../data/PageManager";

export const collabAPIAtom = atom<CollabAPI | null>(null);
export const collabDialogShownAtom = atom(false);
export const isCollaboratingAtom = atom(false);
export const CollaborateAudioVideoWhenAtom = atom("Audio");
export const googleDriveDialogShownAtom = atom(false);
export const toPageAtom = atom("");
export const syncFlagAtom = atom("");
export const loadFromRemoteFlagAtom = atom("");
export const loadFromUserSceneFlagAtom = atom("");
export const syncActionNameFlagAtom = atom("");
export const isGoogleMeetingStartedAtom = atom(false);
export const googleMeetingCodeAtom = atom("");

interface CollabState {
  errorMessage: string;
  username: string;
  activeRoomLink: string;
  activeRoomId: string;
  isHost?: boolean;
}

export interface PageMap {
  [key: string]: any;
}

type CollabInstance = InstanceType<typeof Collab>;

export interface CollabAPI {
  /** function so that we can access the latest value from stale callbacks */
  isCollaborating: () => boolean;
  onPointerUpdate: CollabInstance["onPointerUpdate"];
  startCollaboration: CollabInstance["startCollaboration"];
  stopCollaboration: CollabInstance["stopCollaboration"];
  getActiveRoomLink: CollabInstance["getActiveRoomLink"];
  getActiveRoomId: CollabInstance["getActiveRoomId"];
  syncElements: CollabInstance["syncElements"];
  syncChangeFinished: CollabInstance["syncChangeFinished"];
  fetchImageFilesFromFirebase: CollabInstance["fetchImageFilesFromFirebase"];
  setUsername: (username: string) => void;
  onUsernameChange: (username: string) => void;
  deleteFilesFromCollabData: CollabInstance["deleteFilesFromCollabData"];
  syncFiles: CollabInstance["syncFiles"];
  syncChangePage: CollabInstance["syncChangePage"];
  getLocalPageMap: CollabInstance["getLocalPageMap"];
  toPage: () => string | undefined;
  saveUserScene: CollabInstance["saveUserScene"];
  loadUserSceneData: CollabInstance["loadUserSceneData"];
  syncGoogleMeet: CollabInstance["syncGoogleMeet"];
  setIsGoogleMeeting: CollabInstance["setIsGoogleMeeting"];
  syncViewBackgroundColor: CollabInstance["syncViewBackgroundColor"];
}

interface PublicProps {
  imagoAPI: ImagoImperativeAPI;
}

type Props = PublicProps & { modalIsShown: boolean };

class Collab extends PureComponent<Props, CollabState> {
  portal: Portal;
  fileManager: FileManager;
  imagoAPI: Props["imagoAPI"];
  activeIntervalId: number | null;
  idleTimeoutId: number | null;

  private socketInitializationTimer?: number;
  private lastBroadcastedOrReceivedSceneVersion: number = -1;
  private collaborators = new Map<string, Collaborator>();

  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: "",
      username: importUsernameFromLocalStorage() || "",
      activeRoomLink: "",
      activeRoomId: "",
    };
    this.portal = new Portal(this);
    this.fileManager = new FileManager({
      getFiles: async (fileIds) => {
        const { roomId, roomKey } = this.portal;
        if (!roomId || !roomKey) {
          throw new AbortError();
        }
        return loadFilesFromCollabData(
          roomKey,
          fileIds,
          this.imagoAPI.getAppState(),
        );
        // return loadFilesFromFirebase(`files/rooms/${roomId}`, roomKey, fileIds);
      },
      saveFiles: async ({ addedFiles }) => {
        const { roomId, roomKey } = this.portal;
        if (!roomId || !roomKey) {
          throw new AbortError();
        }
        return saveFilesToCollabData({
          files: await encodeFilesForUpload({
            files: addedFiles,
            encryptionKey: FILE_ENCRYPTION_KEY,
            maxBytes: FILE_UPLOAD_MAX_BYTES,
          }),
          appState: this.imagoAPI.getAppState(),
        });
        // return saveFilesToFirebase({
        //   prefix: `${FIREBASE_STORAGE_PREFIXES.collabFiles}/${roomId}`,
        //   files: await encodeFilesForUpload({
        //     files: addedFiles,
        //     encryptionKey: roomKey,
        //     maxBytes: FILE_UPLOAD_MAX_BYTES,
        //   }),
        // });
      },
    });
    this.imagoAPI = props.imagoAPI;
    this.activeIntervalId = null;
    this.idleTimeoutId = null;
  }

  componentDidMount() {
    window.addEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload);
    window.addEventListener(EVENT.UNLOAD, this.onUnload);

    const collabAPI: CollabAPI = {
      isCollaborating: this.isCollaborating,
      onPointerUpdate: this.onPointerUpdate,
      startCollaboration: this.startCollaboration,
      onUsernameChange: this.onUsernameChange,
      syncElements: this.syncElements,
      syncChangeFinished: this.syncChangeFinished,
      fetchImageFilesFromFirebase: this.fetchImageFilesFromFirebase,
      stopCollaboration: this.stopCollaboration,
      getActiveRoomLink: this.getActiveRoomLink,
      getActiveRoomId: this.getActiveRoomId,
      setUsername: this.setUsername,
      deleteFilesFromCollabData: this.deleteFilesFromCollabData,
      syncFiles: this.syncFiles,
      syncChangePage: this.syncChangePage,
      getLocalPageMap: this.getLocalPageMap,
      toPage: this.toPage,
      saveUserScene: this.saveUserScene,
      loadUserSceneData: this.loadUserSceneData,
      syncGoogleMeet: this.syncGoogleMeet,
      setIsGoogleMeeting: this.setIsGoogleMeeting,
      syncViewBackgroundColor: this.syncViewBackgroundColor,
    };

    jotaiStore.set(collabAPIAtom, collabAPI);

    if (
      process.env.NODE_ENV === ENV.TEST ||
      process.env.NODE_ENV === ENV.DEVELOPMENT
    ) {
      window.collab = window.collab || ({} as Window["collab"]);
      Object.defineProperties(window, {
        collab: {
          configurable: true,
          value: this,
        },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload);
    window.removeEventListener(EVENT.UNLOAD, this.onUnload);
    window.removeEventListener(EVENT.POINTER_MOVE, this.onPointerMove);
    window.removeEventListener(
      EVENT.VISIBILITY_CHANGE,
      this.onVisibilityChange,
    );
    if (this.activeIntervalId) {
      window.clearInterval(this.activeIntervalId);
      this.activeIntervalId = null;
    }
    if (this.idleTimeoutId) {
      window.clearTimeout(this.idleTimeoutId);
      this.idleTimeoutId = null;
    }
  }
  isGoogleMeetAddon = () => jotaiStore.get(isGoogleMeetAddonAtom)!;
  isCollaborating = () => jotaiStore.get(isCollaboratingAtom)!;

  toPage = () => jotaiStore.get(toPageAtom);

  private setIsCollaborating = (isCollaborating: boolean) => {
    jotaiStore.set(isCollaboratingAtom, isCollaborating);
  };

  private onUnload = () => {
    this.destroySocketClient({ isUnload: true });
  };

  private beforeUnload = withBatchedUpdates((event: BeforeUnloadEvent) => {
    const syncableElements = getSyncableElements(
      this.getSceneElementsIncludingDeleted(),
    );

    if (
      this.isCollaborating() &&
      (this.fileManager.shouldPreventUnload(syncableElements) ||
        // !isSavedToFirebase(this.portal, syncableElements))
        !isSavedToCollabData(this.portal, syncableElements))
    ) {
      // this won't run in time if user decides to leave the site, but
      //  the purpose is to run in immediately after user decides to stay

      this.saveCollabRoomToDatabase(syncableElements);

      preventUnload(event);
    }
  });

  getLocalPageMap = async () => {
    const { pageList } = await getPageData();
    const pageMap: PageMap = {};
    for (let index = 0; index < pageList.length; index++) {
      const page = pageList[index];
      const allElements = await LocalData.pagesStorage.get(page.id);
      const elements = getNonDeletedElements(allElements);
      pageMap[page.id] = clearElementsForDatabase(
        getSyncableElements(elements),
      );
    }

    return pageMap;
  };

  saveCollabRoomToDatabase = async (
    syncableElements: readonly SyncableImagoElement[],
  ) => {
    try {
      const pageMap = await this.getLocalPageMap();
      const savedData = await saveToCollabDataNew(
        this.portal,
        getPageListFromStorage(),
        pageMap,
        syncableElements,
        this.imagoAPI.getAppState(),
        true,
      );
      // if (this.isCollaborating() && savedData && savedData.reconciledElements) {
      //   this.handleRemoteSceneUpdate(
      //     this.reconcileElements(savedData.reconciledElements),
      //   );
      // }
      return pageMap;
    } catch (error: any) {
      console.error(error);
    }
  };

  deleteFilesFromCollabData = async (files: BinaryFiles) => {
    await deleteFilesFromCollabData(
      Object.keys(files),
      this.imagoAPI.getAppState(),
    );
  };
  getActiveRoomLink = () => {
    if (this.state.activeRoomLink) {
      return this.state.activeRoomLink;
    }
    return "";
  };

  getActiveRoomId = () => {
    if (this.state.activeRoomId) {
      return this.state.activeRoomId;
    }
    return "";
  };

  stopCollaboration = (keepRemoteState = true) => {
    this.setState(
      {
        activeRoomLink: "",
        activeRoomId: "",
      },
      async () => {
        this.queueBroadcastAllElements.cancel();
        this.queueSaveToDatabase.cancel();
        this.loadImageFiles.cancel();
        this.setIsGoogleMeeting("");

        await this.saveCollabRoomToDatabase(
          getSyncableElements(this.imagoAPI.getSceneElementsIncludingDeleted()),
        );

        if (this.portal.socket && this.fallbackInitializationHandler) {
          this.portal.socket.off(
            "connect_error",
            this.fallbackInitializationHandler,
          );
        }

        if (!keepRemoteState) {
          LocalData.fileStorage.reset();
          this.destroySocketClient();
          window.history.pushState(
            {},
            APP_NAME,
            `${window.location.origin}/board`,
          );
        } else if (window.confirm(t("alerts.collabStopOverridePrompt"))) {
          // hack to ensure that we prefer we disregard any new browser state
          // that could have been saved in other tabs while we were collaborating
          resetBrowserStateVersions();

          window.history.pushState(
            {},
            APP_NAME,
            `${window.location.origin}/board`,
          );
          this.destroySocketClient();

          LocalData.fileStorage.reset();


          jotaiStore.set(isFlashCollabingAtom, false);
          jotaiStore.set(isCollaboratingAtom, false);

          const elements = this.imagoAPI
            .getSceneElementsIncludingDeleted()
            .map((element) => {
              if (isImageElement(element) && element.status === "saved") {
                return newElementWith(element, { status: "pending" });
              }
              return element;
            });

          this.imagoAPI.updateScene({
            elements,
            commitToHistory: false,
            appState: {
              openDialog: null
            }
          });
          reSetCollabtionHost();
          deleteCookie(COOKIES.FLASH4AUTH)
        }
      },
    );
  };

  private destroySocketClient = (opts?: { isUnload: boolean }) => {
    this.lastBroadcastedOrReceivedSceneVersion = -1;
    this.portal.close();
    this.fileManager.reset();
    if (!opts?.isUnload) {
      this.setIsCollaborating(false);
      this.setState({
        activeRoomLink: "",
        activeRoomId: "",
      });
      this.collaborators = new Map();
      this.imagoAPI.updateScene({
        collaborators: this.collaborators,
      });
      LocalData.resumeSave("collaboration");
    }
  };

  private fetchImageFilesFromFirebase = async (opts: {
    elements: readonly ImagoElement[];
    /**
     * Indicates whether to fetch files that are errored or pending and older
     * than 10 seconds.
     *
     * Use this as a machanism to fetch files which may be ok but for some
     * reason their status was not updated correctly.
     */
    forceFetchFiles?: boolean;
  }) => {
    const unfetchedImages = opts.elements
      .filter((element) => {
        return (
          isInitializedImageElement(element) &&
          !this.fileManager.isFileHandled(element.fileId) &&
          !element.isDeleted &&
          (opts.forceFetchFiles
            ? element.status !== "pending" ||
            Date.now() - element.updated > 10000
            : element.status === "saved")
        );
      })
      .map((element) => (element as InitializedImagoImageElement).fileId);

    return await this.fileManager.getFiles(unfetchedImages);
  };

  private decryptPayload = async (
    iv: Uint8Array,
    encryptedData: ArrayBuffer,
    decryptionKey: string,
  ) => {
    try {
      const decrypted = await decryptData(iv, encryptedData, decryptionKey);

      const decodedData = new TextDecoder("utf-8").decode(
        new Uint8Array(decrypted),
      );
      return JSON.parse(decodedData);
    } catch (error) {
      window.alert(t("alerts.decryptFailed"));
      console.error(error);
      return {
        type: "INVALID_RESPONSE",
      };
    }
  };

  private fallbackInitializationHandler: null | (() => any) = null;

  private setIsGoogleMeetingStarted = (value: boolean) => {
    jotaiStore.set(isGoogleMeetingStartedAtom, value);
  };

  private setGoogleMeetingCode = (value: string) => {
    jotaiStore.set(googleMeetingCodeAtom, value);
  };

  private setIsGoogleMeeting = (googleMeetginCode: string) => {
    if (googleMeetginCode) {
      this.setIsGoogleMeetingStarted(true);
      this.setGoogleMeetingCode(googleMeetginCode);
      this.imagoAPI.updateScene({ appState: { showFlashCollabration: false } });
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        `https://meet.google.com/${googleMeetginCode}`,
      );
      element.setAttribute("target", "_blank");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      this.setIsGoogleMeetingStarted(false);
      this.setGoogleMeetingCode("");
    }
  };

  startCollaboration = async (
    existingRoomLinkData: null | { roomId: string; roomKey: string, isHost: boolean },
  ): Promise<ImportedDataState | null> => {
    if (this.portal.socket) {
      return null;
    }

    let roomId = "";
    let roomKey = "";
    let isHost = false;
    const { userInfo, width, height, zoom } = this.imagoAPI.getAppState();

    if (existingRoomLinkData) {
      ({ roomId, roomKey, isHost } = existingRoomLinkData);
    } else {
      ({ roomId, roomKey, isHost } = await generateCollaborationLinkData());
      window.history.pushState(
        {},
        APP_NAME,
        getCollaborationLink({ roomId, roomKey }),
      );

      let currBoardMode = getCurrBoardMode()
      const formData = { roomId, roomKey, mode: currBoardMode, extraInfo: JSON.stringify({ screenWidth: width * zoom.value, screenHeight: height * zoom.value }) };

      await fetch(`${API_URL.host}${API_URL.saveRoom}`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo?.authorization}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const scenePromise = resolvablePromise<ImportedDataState | null>();

    this.setState({ isHost });

    this.setIsCollaborating(true);
    LocalData.pauseSave("collaboration");

    if (!this.isGoogleMeetAddon()) {
      jotaiStore.set(isFlashCollabingAtom, true);
    }

    const { default: socketIOClient } = await import(
      /* webpackChunkName: "socketIoClient" */ "socket.io-client"
    );

    const fallbackInitializationHandler = () => {
      this.initializeRoom({
        roomLinkData: existingRoomLinkData,
        fetchScene: true,
      }).then((scene) => {
        scenePromise.resolve(scene);
      });
    };
    this.fallbackInitializationHandler = fallbackInitializationHandler;

    try {
      const socketServerData = await getCollabServer();

      const response = await fetch(`${API_URL.getLicence}/${roomId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userInfo?.authorization}`,
        },
      });

      if (response.status !== 200) {
        alert("licence error");
        return null;
      }

      const licence = (await response.json()).data;

      jotaiStore.set(CollaborateAudioVideoWhenAtom, licence.audioVideoWhen);

      this.portal.socket = this.portal.open(
        socketIOClient(socketServerData.url, {
          transports: socketServerData.polling
            ? ["websocket", "polling"]
            : ["websocket"],
        }),
        roomId,
        roomKey,
      );



      this.portal.socket.once(
        "room-full",
        ({ roomSize }: { roomSize: number }) => {
          alert(`room full,size:${roomSize}`);
          this.stopCollaboration(false);
          this.imagoAPI.updateScene({
            appState: { showFlashCollabration: false },
          });
        },
      );
      // this.portal.socket.once("room-licence", ({audioVideoWhen}:{audioVideoWhen: string}) => {
      //   jotaiStore.set(CollaborateAudioVideoWhenAtom,audioVideoWhen);
      //   return;
      // });

      if (licence.roomSize <= Object.keys(this.collaborators).length) {
        alert(`room full,size:${licence.roomSize}`);
        this.stopCollaboration(false);
        jotaiStore.set(isFlashCollabingAtom, false);
        return null;
      }

      this.portal.socket.once("connect_error", fallbackInitializationHandler);
    } catch (error: any) {
      console.error(error);
      this.setState({ errorMessage: error.message });
      return null;
    }

    if (!existingRoomLinkData) {
      const elements = this.imagoAPI.getSceneElements().map((element) => {
        if (isImageElement(element) && element.status === "saved") {
          return newElementWith(element, { status: "pending" });
        }
        return element;
      });
      // remove deleted elements from elements array & history to ensure we don't
      // expose potentially sensitive user data in case user manually deletes
      // existing elements (or clears scene), which would otherwise be persisted
      // to database even if deleted before creating the room.
      this.imagoAPI.history.clear();
      this.imagoAPI.updateScene({
        elements,
        commitToHistory: true,
      });

      this.saveCollabRoomToDatabase(getSyncableElements(elements));
    }

    // fallback in case you're not alone in the room but still don't receive
    // initial SCENE_INIT message
    this.socketInitializationTimer = window.setTimeout(
      fallbackInitializationHandler,
      INITIAL_SCENE_UPDATE_TIMEOUT,
    );

    // All socket listeners are moving to Portal
    this.portal.socket.on(
      "client-broadcast",
      async (encryptedDataStr: string, ivStr: string) => {
        if (!this.portal.roomKey) {
          return;
        }

        const encryptedDataArr = Uint8Array.from(atob(encryptedDataStr), (c) =>
          c.charCodeAt(0),
        );
        const iv = Uint8Array.from(atob(ivStr), (c) => c.charCodeAt(0));
        const encryptedData = encryptedDataArr.buffer;
        const decryptedData = await this.decryptPayload(
          iv,
          encryptedData,
          this.portal.roomKey,
        );

        //const decryptedData = encryptedDataStr as any;
        switch (decryptedData.type) {
          case "INVALID_RESPONSE":
            return;
          case WS_SCENE_EVENT_TYPES.INIT: {
            if (!this.portal.socketInitialized) {
              const initialData = await this.initializeRoom({
                fetchScene: true,
                roomLinkData: existingRoomLinkData,
              });
              const remoteElements = decryptedData.payload.elements;
              const reconciledElements = this.reconcileElements(remoteElements);
              this.handleRemoteSceneUpdate(
                reconciledElements,
                {
                  init: true,
                },
                initialData?.currPage || getCurrPageFromStorage(),
              );
              // noop if already resolved via init from firebase
              const page = PageManager.getPage(initialData?.currPage || getCurrPageFromStorage());

              scenePromise.resolve({
                elements: reconciledElements,
                scrollToContent: true,
                appState: {
                  viewBackgroundColor: page.backgroundColor
                }
              });
            }
            break;
          }
          case WS_SCENE_EVENT_TYPES.UPDATE:
            const { page, elements } = decryptedData.payload;
            const currPage = getCurrPageFromStorage();
            if (page === currPage) {
              this.handleRemoteSceneUpdate(
                this.reconcileElements(elements),
                {},
                currPage,
              );
            }

            break;
          case "MOUSE_LOCATION": {
            const { pointer, button, username, selectedElementIds, isHost } =
              decryptedData.payload;
            const socketId: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["socketId"] =
              decryptedData.payload.socketId ||
              // @ts-ignore legacy, see #2094 (#2097)
              decryptedData.payload.socketID;

            const collaborators = new Map(this.collaborators);
            const user = collaborators.get(socketId) || {}!;
            user.pointer = pointer;
            user.button = button;
            user.selectedElementIds = selectedElementIds;
            user.username = username;
            user.isHost = isHost;
            collaborators.set(socketId, user);
            this.imagoAPI.updateScene({
              collaborators,
            });
            break;
          }
          case "IDLE_STATUS": {
            const { userState, socketId, username, isHost } = decryptedData.payload;
            const collaborators = new Map(this.collaborators);
            const user = collaborators.get(socketId) || {}!;
            user.userState = userState;
            user.username = username;
            user.isHost = isHost;
            this.imagoAPI.updateScene({
              collaborators,
            });
            break;
          }
          case "PAGE_CHANGE": {
            const { toPage, actionNameFlag } = decryptedData.payload;
            const sflag = new Date().getTime().toString();
            jotaiStore.set(toPageAtom, toPage);
            jotaiStore.set(syncFlagAtom, sflag);
            jotaiStore.set(syncActionNameFlagAtom, actionNameFlag);
            this.setLastBroadcastedOrReceivedSceneVersion(0);
            console.log("page_change:", decryptedData.payload)
            break;
          }
          case "CHANGE_FINISHED": {
            this.setLastBroadcastedOrReceivedSceneVersion(0);
            break;
          }
          case "FILES_ADD": {
            this.handleRemoteFilesAdd(decryptedData.payload.fileIds);
            break;
          }
          case "ESCALATE_GOOGLEMEET": {
            //this.imagoAPI.escalateGoogleMeet(true);
            this.setIsGoogleMeeting(decryptedData.payload.code);

            break;
          }
          case "CHANGE_BACKGROUND": {
            console.log(decryptedData.payload)
            this.imagoAPI.updateScene({
              appState:
              {
                viewBackgroundColor: decryptedData.payload.backgroundColor
              }
            })
            const pageId = getCurrPageFromStorage();
            const page = PageManager.getPage(pageId);
            page.backgroundColor = decryptedData.payload.backgroundColor;
            PageManager.editPage(page);
            break;
          }
        }
      },
    );

    this.portal.socket.on("first-in-room", async () => {
      if (this.portal.socket) {
        this.portal.socket.off("first-in-room");
      }
      const sceneData = await this.initializeRoom({
        fetchScene: true,
        roomLinkData: existingRoomLinkData,
      });
      scenePromise.resolve(sceneData);
    });

    this.initializeIdleDetector();

    this.setState({
      activeRoomLink: window.location.href,
      activeRoomId: roomId,
    });

    return scenePromise;
  };

  private initializeRoom = async ({
    fetchScene,
    roomLinkData,
  }:
    | {
      fetchScene: true;
      roomLinkData: { roomId: string; roomKey: string } | null;
    }
    | { fetchScene: false; roomLinkData?: null }) => {
    clearTimeout(this.socketInitializationTimer!);
    if (this.portal.socket && this.fallbackInitializationHandler) {
      this.portal.socket.off(
        "connect_error",
        this.fallbackInitializationHandler,
      );
    }

    if (fetchScene && roomLinkData && this.portal.socket) {
      this.imagoAPI.resetScene();

      try {
        // const elements = await loadFromFirebase(
        //   roomLinkData.roomId,
        //   roomLinkData.roomKey,
        //   this.portal.socket,
        // );

        const { elements, currPage } = await loadFromCollabData(
          roomLinkData.roomId,
          roomLinkData.roomKey,
          this.portal.socket,
          this.imagoAPI.getAppState(),
        );

        if (currPage) {
          jotaiStore.set(toPageAtom, currPage);
          jotaiStore.set(
            loadFromRemoteFlagAtom,
            new Date().getTime().toString(),
          );
        }

        if (elements) {
          // this.setLastBroadcastedOrReceivedSceneVersion(
          //   getSceneVersion(elements),
          // );
          const page = PageManager.getPage(currPage || getCurrPageFromStorage());

          return {
            elements,
            scrollToContent: true,
            currPage,
            appState: {
              viewBackgroundColor: page.backgroundColor
            }
          };
        }
      } catch (error: any) {
        // log the error and move on. other peers will sync us the scene.
        console.error(error);
      } finally {
        this.portal.socketInitialized = true;
      }
    } else {
      this.portal.socketInitialized = true;
    }
    return null;
  };

  private reconcileElements = (
    remoteElements: readonly ImagoElement[],
  ): ReconciledElements => {
    const localElements = this.getSceneElementsIncludingDeleted();
    const appState = this.imagoAPI.getAppState();

    remoteElements = restoreElements(remoteElements, null, false);

    const reconciledElements = _reconcileElements(
      localElements,
      remoteElements,
      appState,
    );

    // Avoid broadcasting to the rest of the collaborators the scene
    // we just received!
    // Note: this needs to be set before updating the scene as it
    // synchronously calls render.
    this.setLastBroadcastedOrReceivedSceneVersion(
      getSceneVersion(reconciledElements),
    );

    return reconciledElements;
  };

  private loadImageFiles = throttle(async () => {
    const { loadedFiles, erroredFiles } =
      await this.fetchImageFilesFromFirebase({
        elements: this.imagoAPI.getSceneElementsIncludingDeleted(),
      });

    this.imagoAPI.addFiles(loadedFiles);

    updateStaleImageStatuses({
      imagoAPI: this.imagoAPI,
      erroredFiles,
      elements: this.imagoAPI.getSceneElementsIncludingDeleted(),
    });
  }, LOAD_IMAGES_TIMEOUT);

  private handleRemoteSceneUpdate = (
    elements: ReconciledElements,
    { init = false }: { init?: boolean } = {},
    currentPage: string,
  ) => {

    this.imagoAPI.updateScene({
      elements,
      commitToHistory: !!init,
    });

    // We haven't yet implemented multiplayer undo functionality, so we clear the undo stack
    // when we receive any messages from another peer. This UX can be pretty rough -- if you
    // undo, a user makes a change, and then try to redo, your element(s) will be lost. However,
    // right now we think this is the right tradeoff.
    this.imagoAPI.history.clear();

    this.loadImageFiles();
  };

  private handleRemoteFilesAdd = async (fileIds: FileId[]) => {
    if (fileIds?.length > 0) {
      const { loadedFiles, erroredFiles } = await this.fileManager.getFiles(
        fileIds,
      );
      this.imagoAPI.addFiles(loadedFiles);
    }
  };

  private onPointerMove = () => {
    if (this.idleTimeoutId) {
      window.clearTimeout(this.idleTimeoutId);
      this.idleTimeoutId = null;
    }

    this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD);

    if (!this.activeIntervalId) {
      this.activeIntervalId = window.setInterval(
        this.reportActive,
        ACTIVE_THRESHOLD,
      );
    }
  };

  private onVisibilityChange = () => {
    if (document.hidden) {
      if (this.idleTimeoutId) {
        window.clearTimeout(this.idleTimeoutId);
        this.idleTimeoutId = null;
      }
      if (this.activeIntervalId) {
        window.clearInterval(this.activeIntervalId);
        this.activeIntervalId = null;
      }
      this.onIdleStateChange(UserIdleState.AWAY);
    } else {
      this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD);
      this.activeIntervalId = window.setInterval(
        this.reportActive,
        ACTIVE_THRESHOLD,
      );
      this.onIdleStateChange(UserIdleState.ACTIVE);
    }
  };

  private reportIdle = () => {
    this.onIdleStateChange(UserIdleState.IDLE);
    if (this.activeIntervalId) {
      window.clearInterval(this.activeIntervalId);
      this.activeIntervalId = null;
    }
  };

  private reportActive = () => {
    this.onIdleStateChange(UserIdleState.ACTIVE);
  };

  private initializeIdleDetector = () => {
    document.addEventListener(EVENT.POINTER_MOVE, this.onPointerMove);
    document.addEventListener(EVENT.VISIBILITY_CHANGE, this.onVisibilityChange);
  };

  initCollaborators(socketId: string, data: Partial<Collaborator>) {
    const collaborators: InstanceType<typeof Collab>["collaborators"] =
      new Map();

    if (!this.collaborators.has(socketId)) {
      collaborators.set(socketId, { username: this.state.username, ...data, isHost: this.state.isHost });
    }

    this.collaborators = collaborators;

    this.imagoAPI.updateScene({ collaborators });
  }

  setCollaborators(sockets: string[]) {
    const collaborators: InstanceType<typeof Collab>["collaborators"] =
      new Map();
    for (const socketId of sockets) {
      if (this.collaborators.has(socketId)) {
        collaborators.set(socketId, this.collaborators.get(socketId)!);
      } else {
        collaborators.set(socketId, {});
      }
    }
    this.collaborators = collaborators;

    this.imagoAPI.updateScene({ collaborators });
  }

  public setLastBroadcastedOrReceivedSceneVersion = (version: number) => {
    this.lastBroadcastedOrReceivedSceneVersion = version;
  };

  public getLastBroadcastedOrReceivedSceneVersion = () => {
    return this.lastBroadcastedOrReceivedSceneVersion;
  };

  public getFiles = () => {
    const files = this.imagoAPI.getFiles();
    return Object.keys(files)?.map((k) => files[k]);
  };

  public getSceneElementsIncludingDeleted = () => {
    return this.imagoAPI.getSceneElementsIncludingDeleted();
  };

  onPointerUpdate = throttle(
    (payload: {
      pointer: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["pointer"];
      button: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["button"];
      pointersMap: Gesture["pointers"];
    }) => {
      payload.pointersMap.size < 2 &&
        this.portal.socket &&
        this.portal.broadcastMouseLocation(payload);
    },
    CURSOR_SYNC_TIMEOUT,
  );

  onIdleStateChange = (userState: UserIdleState) => {
    this.portal.broadcastIdleChange(userState);
  };

  broadcastElements = throttle((elements: readonly ImagoElement[]) => {
    if (
      getSceneVersion(elements) >
      this.getLastBroadcastedOrReceivedSceneVersion()
    ) {
      this.portal.broadcastScene(
        WS_SCENE_EVENT_TYPES.UPDATE,
        elements,
        false,
        getCurrPageFromStorage(),
      );
      this.lastBroadcastedOrReceivedSceneVersion = getSceneVersion(elements);
      this.queueBroadcastAllElements();
    }
  }, 500);

  syncFiles = (files: BinaryFileData[]) => {
    this.portal.broadcastFiles(files);
  };

  syncViewBackgroundColor = (page: { pageId: string, backgroundColor: string, gridColor: string }) => {
    this.portal.broadcastViewBackgroundColor(page);
  }

  // syncChangePage = throttle(async ({ toPage, actionNameFlag }: { toPage: string, actionNameFlag?: string }) => {
  //   const pageMap = await this.getLocalPageMap();
  //   await this.portal.broadcastBoardPageChange({ actionNameFlag, toPage })
  // }, 2000);

  syncChangePage = async ({
    toPage,
    actionNameFlag,
    pageName,
  }: {
    toPage: string;
    actionNameFlag?: string;
    pageName?: string;
  }) => {
    await this.portal.broadcastBoardPageChange({
      actionNameFlag,
      toPage,
      pageName
    });
  };

  syncChangeFinished = async ({ toPage }: { toPage: string }) => {
    await this.portal.broadcastPageChangeFinished({ toPage });
  };

  saveUserScene = throttle(async () => {
    //const pageMap = await this.getLocalPageMap();
    const pageElements = (await LocalData.pagesStorage.getAll()) || {};
    const paginations = getPageListFromStorage();
    for (const p of paginations) {
      const pageMap: PageMap = {};
      pageMap[p.id] = pageElements[p.id];

      await saveUserSceneData(
        paginations,
        pageMap,
        this.imagoAPI.getAppState(),
      );
    }
  }, 10000);

  loadUserSceneData = async () => {
    const { currPage } = await loadUserSceneFromStoreData(
      this.imagoAPI.getAppState(),
    );
    if (currPage) {
      jotaiStore.set(
        loadFromUserSceneFlagAtom,
        new Date().getTime().toString(),
      );
    }
  };

  syncElements = (elements: readonly ImagoElement[]) => {
    this.broadcastElements(elements);
    this.queueSaveToDatabase();
  };

  queueBroadcastAllElements = throttle(() => {
    this.portal.broadcastScene(
      WS_SCENE_EVENT_TYPES.UPDATE,
      this.imagoAPI.getSceneElementsIncludingDeleted(),
      true,
      getCurrPageFromStorage(),
    );
    const currentVersion = this.getLastBroadcastedOrReceivedSceneVersion();
    const newVersion = Math.max(
      currentVersion,
      getSceneVersion(this.getSceneElementsIncludingDeleted()),
    );
    this.setLastBroadcastedOrReceivedSceneVersion(newVersion);
  }, SYNC_FULL_SCENE_INTERVAL_MS);

  queueSaveToDatabase = throttle(
    () => {
      if (this.portal.socketInitialized) {
        this.saveCollabRoomToDatabase(
          getSyncableElements(this.imagoAPI.getSceneElementsIncludingDeleted()),
        );
      }
    },
    SYNC_FULL_SCENE_INTERVAL_MS,
    { leading: false },
  );

  handleClose = () => {
    jotaiStore.set(collabDialogShownAtom, false);
  };

  setUsername = (username: string) => {
    this.setState({ username });
  };

  onUsernameChange = (username: string) => {
    this.setState({ username }, () => {
      saveUsernameToLocalStorage(username);
    });
  };

  syncGoogleMeet = (code: string) => {
    this.portal.broadcastEscalate(code);
  };

  render() {
    const { username, errorMessage, activeRoomLink, activeRoomId } = this.state;

    const { modalIsShown } = this.props;

    return <></>;
  }
}

declare global {
  interface Window {
    collab: InstanceType<typeof Collab>;
  }
}

if (
  process.env.NODE_ENV === ENV.TEST ||
  process.env.NODE_ENV === ENV.DEVELOPMENT
) {
  window.collab = window.collab || ({} as Window["collab"]);
}

// window.collab = window.collab || ({} as Window["collab"]);

const _Collab: React.FC<PublicProps> = (props) => {
  const [collabDialogShown] = useAtom(collabDialogShownAtom);
  const [googleDriveDialogShown] = useAtom(googleDriveDialogShownAtom);
  return <Collab {...props} modalIsShown={collabDialogShown} />;
};

export default _Collab;

export type TCollabClass = Collab;
